import React from 'react';
import ReactDOM from 'react-dom';

import { NotariesProvider } from './utils/Notaries'

import { ApolloProvider } from '@apollo/client';

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { onError } from "@apollo/client/link/error";

import Main from './screens/Main';
import Firebase, { FirebaseContext } from './utils/Firebase';

import { SnackbarProvider } from 'notistack';

import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

console.log("............I N D E X..................");
console.log(process.env.NODE_ENV);
console.log("process.env.REACT_APP_endpoint");
console.log(process.env.REACT_APP_endpoint);

const token = localStorage.getItem("token");
console.log(`token`)
console.log(token)
console.log(`REACT_APP_PRISMA_SECRET`)
console.log(process.env.REACT_APP_PRISMA_SECRET)


const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_endpoint
      : process.env.REACT_APP_prodEndpoint
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  console.log(`token`)
  console.log(token)
  return {
    headers: {
      ...headers,
      userAuthorization: token ? `Bearer ${token}` : '',
      authorization: `Bearer ${process.env.REACT_APP_PRISMA_SECRET}`
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log("****************************");
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.log("*****************************");
    console.log(`[Network error]: ${networkError}`);
  }
});



const client = new ApolloClient({
  link: authLink.concat(errorLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

var hist = createBrowserHistory();

ReactDOM.render(
  <NotariesProvider>
    <Router history={hist}>
      <FirebaseContext.Provider value={new Firebase()}>
        <ApolloProvider client={client}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Main client={client} />
          </SnackbarProvider>
        </ApolloProvider>
      </FirebaseContext.Provider>
    </Router>
  </NotariesProvider>,
  document.getElementById('root')
);
